export function randomArrayItem(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function isEqual(value, other) {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  const compare = (item1, item2) => {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    // If the two items are not the same type, return false
    if (itemType !== Object.prototype.toString.call(item2)) return false;

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (itemType === '[object Function]') {
      if (item1.toString() !== item2.toString()) return false;
    } else if (item1 !== item2) return false;
    return true;
  };

  // Compare properties
  if (type === '[object Array]') {
    // eslint-disable-next-line
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (const key in value) {
      // eslint-disable-next-line
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
}

/* eslint no-restricted-syntax:0 */
export function compact(array) {
  let resIndex = 0;
  const result = [];

  if (array == null) {
    return result;
  }

  for (const value of array) {
    if (value) {
      result[resIndex += 1] = value;
    }
  }
  return result;
}

export function uniqBy(array, condition) {
  const result = {};
  if (!condition) {
    return array;
  }

  array.forEach((item) => {
    result[condition(item)] = item;
  });
  return Object.values(result);
}

export function mergeArray(arr1, arr2, replace = true, array1findBy = null, array2findBy = array1findBy){
  if(!array1findBy){
    for (let key in arr2){
      if (arr2.hasOwnProperty(key)){
        if(replace)
          arr1[key] = arr2[key];
        else
          arr1[key] = {...arr1[key], ...arr2[key]};
      }
    }
    return arr1;
  } else {
    let result = {};
    let temp;
    for (let item of arr1){
      if(!result[item[array1findBy]]){
        result[item[array1findBy]] = item;
      } else {
        if(!replace) {
          result[item[array1findBy]] = {  ...result[item[array1findBy]], ...item};
        }
      }
      temp = arr2.shift();
      if(temp) {
        if(replace){
          result[temp[array2findBy]] = temp;
        } else {
          if(!result[temp[array2findBy]]) {
            result[temp[array2findBy]] = temp;
          } else {
            result[temp[array2findBy]] = {...temp, ...result[temp[array2findBy]]};
          }
        }
      }
    }
    return Array.from(Object.keys(result), k => result[k]);
  }
}

export function combineArray(arr1, arr2, array1findBy = 'ID', array2findBy = array1findBy) {
  let result = [];
  let temp = {};
  for (let item of arr2){
    temp[item[array2findBy]] = item;
  }
  for (let item of arr1){
    result.push({...item, ...{combine: temp[item[array1findBy]]}});
  }
  return result;
}

export const removeItemInList = (list = [], item) => {
  const _list = [...list];
  const foundIndex = _list?.findIndex(i => i?.id === item?.id);
  if (foundIndex !== -1) {
    _list.splice(foundIndex,1);
  }
  return _list;
};

export const updateItemInList = (list = [], item) => {
  const _list = [...list];
  const foundIndex = _list?.findIndex(i => i?.id === item?.id);
  if (foundIndex !== -1) {
    _list[foundIndex] = item;
  }
  return _list;
};
