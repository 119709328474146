import pic1 from './img/pic1.svg';
import pic10 from './img/pic10.svg';
import pic11 from './img/pic11.svg';
import pic12 from './img/pic12.svg';
import pic13 from './img/pic13.svg';
import pic14 from './img/pic14.svg';
import pic2 from './img/pic2.svg';
import pic3 from './img/pic3.png';
import pic4 from './img/pic4.svg';
import pic5 from './img/pic5.svg';
import pic6 from './img/pic6.svg';
import pic7 from './img/pic7.svg';
import pic9 from './img/pic9.svg';

export const OurPressData = [
  {
    key: 'usnews',
    name: 'US News & World Report',
    img: pic14,
    url: 'https://money.usnews.com/investing/stock-market-news/articles/defi-101-a-guide-to-decentralized-finance',
    content: `
        <p class="quote">
          "Where these interests overlap, new DeFi products emerge, such as cryptobacked lending. You invest (U.S. dollars) or a stablecoin in a borrower who stakes cryptocurrency as collateral and in return receive interest," Roper says.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">John Divine</div>
          <div class="nick">Senior Investing Reporter</div>
        </div>
    `
  },
  {
    key: 'forbes',
    name: 'Forbes',
    img: pic13,
    url: 'https://www.forbes.com/sites/stephanieburns/2020/07/15/how-to-build-a-service-that-works-for-your-customers-247/#232547a71939',
    content: `
        <p class="quote">
          We want to make sure that anyone who is considering or ready to make an investment decision — at all hours of the day — can easily access all relevant information to feel secure in making the decision.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Zon Chu</div>
          <div class="nick">MyConstant CEO</div>
        </div>
    `
  },
  {
    key: 'entrepreneur',
    name: 'Entrepreneur',
    img: pic2,
    url: 'https://www.entrepreneur.com/article/344621',
    content: `
        <p class="quote">
          Peer-to-peer (P2P) lending does both by removing brokers from loan transactions and by directly matching borrowers and lenders via shared platform. In MyConstant’s case, the platform acts as a trading hub where borrowers and creditors pick the best deal.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Aimee Tariq</div>
          <div class="nick">Entrepreneur Leadership Network Contributor</div>
        </div>
    `
  },
  {
    key: 'inc',
    name: 'Inc',
    img: pic1,
    url: 'https://www.inc.com/kenny-kline/startups-have-these-advantages-over-large-enterprises.html',
    content: `
        <p class="quote">
          An example of a peer-to-peer lending platform is MyConstant which allows borrowers and lenders to transact directly… MyConstant lowers borrowing costs by removing middlemen, and thereby streamlining processes.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Kenny Kline</div>
          <div class="nick">Founder, Jakk Solutions</div>
        </div>
    `
  },
  {
    key: 'bitcompare',
    name: 'Bitcompare',
    img: pic3,
    url: 'https://bitcompare.net/platforms/myconstant/loans',
    content: `
        <p class="quote">
          Crypto loans are becoming increasingly popular, however, many people have concerns about centralized exchanges. MyConstant has been around since 2019 with no losses on their platform - they offer low APR rates too. So if you're looking for the best place to loan out your cryptocurrencies, this might be it.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Dean Fankhauser</div>
          <div class="nick">CEO and Founder, Bitcompare</div>
        </div>
    `
  },
  {
    key: 'hackernoon',
    name: 'Hacker Noon',
    img: pic4,
    url: 'https://hackernoon.com/how-we-built-constant-a-secured-p2p-lending-platform-that-puts-customers-in-control-6820e32d8402',
    content: `
        <p class="quote">
          What follows describes our first product: decentralized, fully-secured P2P lending. Access to capital is key — to start or grow businesses, fund personal goals, support relationships, and pursue a higher quality of life. The more affordable capital we can provide, the more helpful we can be.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Duy Huynh</div>
          <div class="nick">Founder, MyConstant</div>
        </div>
    `
  },
  {
    key: 'coinspeaker',
    name: 'CoinSpeaker',
    img: pic7,
    url: 'https://www.coinspeaker.com/constant-p2p-lending-growth/',
    content: `
        <p class="quote">
          For investors, the transparency, rates, and accessibility of DeFi platforms like MyConstant should not be overlooked. They represent a marked departure from trust issues plaguing conventional finance, and innovation by project’s like MyConstant breathe fresh air into an outdated credit market.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Julia Sakovich</div>
          <div class="nick">Editor-in-Chief</div>
        </div>
    `
  },
  {
    key: 'yahoo',
    name: 'Yahoo finance',
    img: pic12,
    url: 'https://finance.yahoo.com/news/china-us-relations-sour-could-214648110.html?.tsrc=fin-srch',
    content: `
        <p class="quote">
          Crypto-backed lending could therefore bolster a diversified investment strategy and a soft entry to cryptocurrency markets. While the best returns are typically in fixed-term loans, investors could also earn a passive income on idle funds deposited in a lending pool, with the advantage of anytime withdrawals.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Chris Roper</div>
          <div class="nick">Communications Manager</div>
          <div class="nick">MyConstant</div>
        </div>
    `
  },
  {
    key: 'techbollion',
    name: 'Techbullion',
    img: pic6,
    url: 'https://techbullion.com/why-constants-rise-is-indicative-of-the-blossoming-class-of-defi-platforms/',
    content: `
        <p class="quote">
          Open access to platforms like MyConstant is in the best interest of most mainstream investors in many cases too. The transparency and accessibility of DeFi help to mitigate much of the costs and risks associated with traditional options like equities and bonds.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Angela Scott-Briggs</div>
          <div class="nick">Editor</div>
        </div>
    `
  },
  {
    key: 'zycrypto',
    name: 'ZyCrypto',
    img: pic9,
    url: 'https://cryptogazette.com/how-constant-is-pioneering-the-exploration-of-a-new-fintech-generation',
    content: `
        <p class="quote">
          Constant, a leading secured P2P lending platform, has been expanding its service and product offerings in recent months as it continues to originate more loans on its Ethereum-based lending service. With over $3 million loans originated in under 4 months, the platform is continuing its success by diving into emerging markets, particularly cryptocurrencies.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Eduard Watson</div>
          <div class="nick">Experienced Finance Writer</div>
        </div>
    `
  },
  {
    key: 'techworm',
    name: 'Tech Worm',
    img: pic11,
    url: 'https://www.techworm.net/2019/09/technology-changing-way-finance.html',
    content: `
        <p class="quote">
          The best thing about Constant, however, is the security the lending platform has in place. Every loan is fully secured and backed in multiple ways. While on loan it’s protected by borrower collateral, and while waiting to be matched it’s protected by a $130M insurance policy.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Payel Dutta</div>
          <div class="nick">Journalist</div>
        </div>
    `
  },
  {
    key: 'blockonomi',
    name: 'Block ONOMI',
    img: pic10,
    url: 'https://blockonomi.com/constant-decentralized-p2p-lending/',
    content: `
        <p class="quote">
          The shift away from centralized power structures is happening, and P2P lending is a big part of replacing systems that don’t really help society as a whole. Constant has built a great platform that allows P2P lending at near-zero risk for lenders, which should help to pump-up liquidity in the P2P lending space.
        </p>
    `,
    author: `
        <div class="author">
          <div class="name">Nicholas Say</div>
          <div class="nick">Technology Writer</div>
        </div>
    `
  },
  // {
  //   key: 'endchain',
  //   name: 'End The Chain',
  //   img: pic5,
  //   url: 'https://endofthechain.com/constant-secured-p2p-lending-platform-review/',
  //   content: `
  //       <p class="quote">
  //         I've really enjoyed learning about and investing with Constant. It's a great service, with free deposits and withdrawals, extremely fast responding customer support and a transparent team.
  //       </p>
  //   `,
  //   author: `
  //       <div class="author">
  //         <div class="name">Samuel McCulloch</div>
  //         <div class="nick">Founder, End of the Chain</div>
  //       </div>
  //   `
  // },
];
