import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';

import { URL } from 'src/resources/constants/url';
import { randomArrayItem } from 'src/utils/array';
import { HtmlLang } from 'src/lang/components';
import BlockSection from 'src/components/blocksection';
import { loadLazyBackground, loadLazyImage } from 'src/utils/images';

import { OurPressData } from './data';
import goToLink from './img/goToLink.svg';
import IcQuote from './img/ic_quote.svg';
import styles from './styles.scss';
import bg from './img/bg.webp';
import ga, { events } from 'src/services/ga';
import localStore from 'src/services/localStore';
import { USER } from 'src/resources/constants/user';

const renderDescription = item => {
  if (React.isValidElement(item) || typeof item === 'string') return item;
  return null;
};

const PartnersBlock = props => {
  const [partner, setParner] = useState(OurPressData[0]);
  const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';

  useEffect(()=> {
    loadLazyImage();
    loadLazyBackground();
    const timer = setInterval(() => {
      const press = randomArrayItem(OurPressData);
      setParner(press);
    }, 15000);

    return () => {
      timer && clearInterval(timer);
    };

  }, []);

  const handleViewPress = () => {
    switch (props?.location?.pathname) {
      case URL.CRYPTO_LOAN: {
        ga(events.category.borrow, events.action.borrow_viewpress_button, email);
        break;
      }
    }

    if(props?.openNewTab) {
      window.open(URL.TESTIMONIALS, '_blank');
    } else {
      props.history.push(URL.TESTIMONIALS);
    }
  }

  return (
    <BlockSection className={cx(styles.ourPress, 'bgHomeC')} containerClassName={styles.container}>
      <div className={cx(styles.binance, 'lazy-background')}>
        <img alt="" src={IcQuote} className={styles.iconQuote} />
        <div dangerouslySetInnerHTML={{ __html: renderDescription(partner.content), }} />
        <a href={partner.url} target="_blank">
          <div dangerouslySetInnerHTML={{ __html: renderDescription(partner.author), }} />
        </a>
      </div>
      <img className={cx(styles.bgOurPress, 'lazy-image')} data-src={bg}/>
      <div className={styles.rowContent}>
        <div className={styles.content}>
          {OurPressData.map((press, i) => {
            const { name, img, key } = press;
            return (
              <div
                key={name}
                role="presentation"
                onClick={() => setParner(press)}
                className={` ${
                  key === partner.key ? styles.partnerActive : ''
                }`}
              >
                <img className="lazy-image" data-src={img} alt={`${name} writes about MyConstant`} width={120} height={20} />
              </div>
            );
          })}
        </div>
        <div className={styles.description}>
          <h2 style={{ maxWidth: '480px', color: 'white' }} className="crowdin">
            <HtmlLang id="ourPress.title" />
          </h2>
          <div>
            <HtmlLang id="ourPress.desc" />
          </div>
          <button type="button" className={styles.ourPressButton} onClick={handleViewPress}>
            View press <img src={goToLink} alt="myconstant.com" />
          </button>
        </div>
      </div>
    </BlockSection>
  );
};

export default withRouter(PartnersBlock);
